import React, { useContext } from 'react';
import { FooterContext } from '../../context';
import ZoomControls from '../ZoomControls/ZoomControls';

const AppControls = () => {
	const { setFooterIsVisible } = useContext(FooterContext);
	const isMobile = window.matchMedia('(max-width: 768px)').matches;

	return (
		<div className="app__bottom_controls">
			<div className="app__bottom_left_controls app_controls">
				<a
					href="https://opencity.life/add_point"
					target="_balnk"
					rel="noreferrer"
					className="app_controls__item app_control_btn"
				>
					<div className="app_control_btn__bg"></div>
					<div className="app_control_btn__content">
						<div className="app_control_btn__icon_wrapper app_control_btn__icon_wrapper--blue">
							<svg className="app_control_btn__icon">
								<use xlinkHref="#plus"></use>
							</svg>
						</div>
						<span className="app_control_btn__text">
							Добавить точку
						</span>
					</div>
				</a>
				<a
					href="https://opencity.life/donate"
					target="_blank"
					rel="noreferrer"
					className="app_controls__item app_control_btn"
				>
					<div className="app_control_btn__bg"></div>
					<div className="app_control_btn__content">
						<div className="app_control_btn__icon_wrapper app_control_btn__icon_wrapper--pink">
							<svg className="app_control_btn__icon">
								<use xlinkHref="#heart"></use>
							</svg>
						</div>
						<span className="app_control_btn__text">
							Помочь проекту
						</span>
					</div>
				</a>
				{!isMobile && (
					<button
						className="app_controls__item app_control_btn"
						onClick={_ => setFooterIsVisible(true)}
					>
						<div className="app_control_btn__bg"></div>
						<div className="app_control_btn__content">
							<div className="app_control_btn__icon_wrapper app_control_btn__icon_wrapper--green">
								<svg className="app_control_btn__icon">
									<use xlinkHref="#dots"></use>
								</svg>
							</div>
							<span className="app_control_btn__text">
								Системная информация
							</span>
						</div>
					</button>
				)}
			</div>
			{!isMobile && <ZoomControls />}
		</div>
	);
};

export default AppControls;
