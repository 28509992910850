import React, { useState, useContext, useEffect, useRef } from 'react';
import CitiesList from '../CitiesList/CitiesList';
import FiltersList from '../FiltersList/FiltersList';
import { HeaderActiveModalContext, MapContext } from '../../context';

import './Header.css';
import NearbyLocator from '../NearbyLocator/NearbyLocator';
import Search from '../Search/Search';
import Menu from '../Menu/Menu';

const Header = () => {
	const { activeModal, setActiveModal } = useContext(
		HeaderActiveModalContext
	);
	const { mapIsMoving } = useContext(MapContext);

	useEffect(
		_ => {
			mapIsMoving && setActiveModal(null);
		},
		[mapIsMoving]
	);

	const isMobile = window.matchMedia('(max-width: 768px)').matches;

	return (
		<>
			<header className="app_header header">
				<a className="header__main_link" href="https://opencity.life">
					<img
						className="header__logo"
						src="/assets/img/logo.svg"
						alt="Логотип"
					/>
				</a>
				{!isMobile && <Search />}
				<div className="header__btns">
					{!isMobile && <FiltersList />}
					{!isMobile && <NearbyLocator />}
					{!isMobile && <CitiesList />}
					<Menu />
				</div>
			</header>
			{isMobile && (
				<div className="under_header_controls">
					<FiltersList />
					<NearbyLocator />
					<CitiesList />
				</div>
			)}
		</>
	);
};

export default Header;
