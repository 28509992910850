import React, { useState, useEffect } from 'react';

const RippleEffect = ({ clickTarget, clickCoords }) => {
	const rect = clickTarget.getBoundingClientRect();

	

	const [coords, setCoords] = useState({
		x: clickCoords.x - rect.left,
		y: clickCoords.y - rect.top,
	});
	const [isRippling, setIsRippling] = useState(true);

	useEffect(
		_ => {
			if (!isRippling) setCoords({ x: -1, y: -1 });
		},
		[isRippling]
	);

	useEffect(
		_ => {
			if (coords.x !== -1 && coords.y !== -1) {
				setIsRippling(true);
				setTimeout(_ => setIsRippling(false), 1500);
			} else setIsRippling(false);
		},
		[coords]
	);

	return (
		<span
			className="ripple"
			style={{
				left: coords.x,
				top: coords.y,
			}}
		></span>
	);
};

export default RippleEffect;
