import React, { useContext } from 'react';
import { PortalsRefsContext } from '../../context';

const SearchListContainer = () => {
	const { searchResultsRootRef } = useContext(PortalsRefsContext);
	return (
		<div
			ref={searchResultsRootRef}
			id="searchResultsRoot"
			className="search_list"
		></div>
	);
};

export default SearchListContainer;
