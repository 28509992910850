import {createContext, useEffect, useMemo, useRef, useState} from 'react';

export const MapContext = createContext(null);
export const ActivePointContext = createContext(null);
export const CitiesContext = createContext(null);
export const PortalsRefsContext = createContext(null);
export const HeaderActiveModalContext = createContext(null);
export const FiltersContext = createContext(null);
export const SearchContext = createContext(null);
export const FooterContext = createContext(null);
export const ReviewContext = createContext(null);
export const FeedbackContext = createContext(null);


export function MapContextProvider({ children }) {
	const [mapIsLoaded, setMapIsLoaded] = useState(false);
	const [mapIsMoving, setMapIsMoving] = useState(false);
	const mapRef = useRef(null);
	window.mapRef = mapRef;
	const [prevCameraOptions, setPrevCameraOptions] = useState({});
	const [userPosition, setUserPosition] = useState(null);
	const [userPositionRadius, setUserPositionRadius] = useState(null);

	const [points, setPoints] = useState({
		type: 'FeatureCollection',
		features: [],
	});
	return (
		<MapContext.Provider
			value={useMemo(
				_ => ({
					mapIsLoaded,
					setMapIsLoaded,
					mapRef,
					mapIsMoving,
					setMapIsMoving,
					prevCameraOptions,
					setPrevCameraOptions,
					userPosition,
					setUserPosition,
					userPositionRadius,
					setUserPositionRadius,
					points,
					setPoints,
				}),
				[
					mapIsLoaded,
					setMapIsLoaded,
					mapRef,
					mapIsMoving,
					setMapIsMoving,
					prevCameraOptions,
					setPrevCameraOptions,
					userPosition,
					setUserPosition,
					userPositionRadius,
					setUserPositionRadius,
					points,
					setPoints,
				]
			)}
		>
			{children}
		</MapContext.Provider>
	);
}

export function ActivePointContextProvider({ children }) {
	const [activePoint, setActivePoint] = useState(null);
	const [activePointIsVisible, setActivePointIsVisible] = useState(false);
	useEffect(
		_ => {
			setActivePointIsVisible(!!activePoint);
		},
		[activePoint]
	);
	return (
		<ActivePointContext.Provider
			value={useMemo(
				_ => ({
					activePoint,
					setActivePoint,
					activePointIsVisible,
					setActivePointIsVisible,
				}),
				[
					activePoint,
					setActivePoint,
					activePointIsVisible,
					setActivePointIsVisible,
				]
			)}
		>
			{children}
		</ActivePointContext.Provider>
	);
}

export function PortalsRefsContextProvider({ children }) {
	const citiesRootRef = useRef(null);
	const searchResultsRootRef = useRef(null);
	const menuRootRef = useRef(null);
	const reviewRootRef = useRef(null);
	const filtersRootRef = useRef(null);

	return (
		<PortalsRefsContext.Provider
			value={useMemo(
				_ => ({
					citiesRootRef,
					searchResultsRootRef,
					menuRootRef,
					reviewRootRef,
					filtersRootRef,
				}),
				[
					citiesRootRef,
					searchResultsRootRef,
					menuRootRef,
					reviewRootRef,
					filtersRootRef,
				]
			)}
		>
			{children}
		</PortalsRefsContext.Provider>
	);
}

export function CitiesContextProvider({ children }) {
	const [cities, setCities] = useState({
		type: 'FeatureCollection',
		features: [],
	});
	const [currentCity, setCurrentCity] = useState(null);

	return (
		<CitiesContext.Provider
			value={useMemo(
				_ => ({
					cities,
					setCities,
					currentCity,
					setCurrentCity,
				}),
				[cities, setCities, currentCity, setCurrentCity]
			)}
		>
			{children}
		</CitiesContext.Provider>
	);
}

export function FiltersContextProvider({ children }) {
	const [filters, setFilters] = useState(new Map());
	return (
		<FiltersContext.Provider
			value={useMemo(
				_ => ({
					filters,
					setFilters,
				}),
				[filters, setFilters]
			)}
		>
			{children}
		</FiltersContext.Provider>
	);
}

export function FooterContextProvider({ children }) {
	const [footerIsVisible, setFooterIsVisible] = useState(false);
	return (
		<FooterContext.Provider
			value={useMemo(
				_ => ({
					footerIsVisible,
					setFooterIsVisible,
				}),
				[footerIsVisible, setFooterIsVisible]
			)}
		>
			{children}
		</FooterContext.Provider>
	);
}

export function FeedbackContextProvider({ children }) {
	const [feedbackIsVisible, setFeedbackIsVisible] = useState(false);

	return (
		<FeedbackContext.Provider
			value={useMemo(
				_ => ({
					feedbackIsVisible,
					setFeedbackIsVisible,
				}),
				[feedbackIsVisible, setFeedbackIsVisible]
			)}
		>
			{children}
		</FeedbackContext.Provider>
	);
}

export function HeaderActiveModalContextProvider({ children }) {
	const [activeModal, setActiveModal] = useState(null);
	return (
		<HeaderActiveModalContext.Provider
			value={useMemo(
				_ => ({
					activeModal,
					setActiveModal,
				}),
				[activeModal, setActiveModal]
			)}
		>
			{children}
		</HeaderActiveModalContext.Provider>
	);
}
