import React, { useContext } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { ActivePointContext } from '../context';
import AppMap from './Map/Map';
import PointCard from './PointCard/PointCard';

const AppRouter = () => {
	const { activePoint, activePointIsVisible, setActivePoint } =
		useContext(ActivePointContext);
	const navigate = useNavigate();
	return (
		<Routes>
			<Route path="/" element={<AppMap />}>
				<Route
					path="/point/:id"
					element={
						<CSSTransition
							in={activePointIsVisible}
							unmountOnExit
							classNames="point_card"
							onExited={_ => {
								setActivePoint(null);
								navigate('/');
							}}
							timeout={250}
						>
							<>{!!activePoint && <PointCard />}</>
						</CSSTransition>
					}
				/>
			</Route>
		</Routes>
	);
};

export default AppRouter;
