import React, { useContext } from 'react';
import { HeaderActiveModalContext } from '../../context';
import MenuIcons from './MenuIcons';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';


const links = [
	{
		href: 'https://map.opencity.life/',
		icon: '#menu1',
		name: 'Карта',
	},
	{
		href: 'https://opencity.life/events',
		icon: '#menu2',
		name: 'События',
	},
	{
		href: 'https://opencity.life/rally_point',
		icon: '#menu3',
		name: 'Точка сбора',
	},
	{
		href: 'https://opencity.life/shop',
		icon: '#menu11',
		name: 'Магазин',
	},
	{
		href: 'https://opencity.life/project',
		icon: '#menu6',
		name: 'О проекте',
	},
	{
		href: 'https://opencity.life/donate',
		icon: '#menu4',
		name: 'Помочь проекту',
	},
	{
		href: 'https://opencity.life/faq',
		icon: '#menu7',
		name: 'Ответы на вопросы',
	},
	{
		href: 'https://opencity.life/contacts',
		icon: '#menu8',
		name: 'Контакты',
	},
	{
		href: 'https://opencity.life/partners',
		icon: '#menu9',
		name: 'Партнёры',
	},
	{
		href: 'https://opencity.life/privacy',
		icon: '#menu10',
		name: 'Конфиденциальность',
	},
];

const MenuContent = () => {
	const { activeModal, setActiveModal } = useContext(
		HeaderActiveModalContext
	);

	const handleOverlayClick = _ => {
		setActiveModal(null);
	};

	const isMobile = window.matchMedia('(max-width: 768px)').matches;
	
	return (
		<div className="menu">
			<MenuIcons />
			<div className="menu__overlay" onClick={handleOverlayClick}></div>

			<div className="menu__content">
				<SimpleBar style={{ maxHeight: window.innerHeight - 50 }}>
					<a
						href="https://opencity.life"
						target="_blank"
						rel="noreferrer"
						className="menu__logo"
					>
						<img
							className="menu__logo_img"
							src="/assets/img/logo.svg"
							alt="Логотип"
						/>
					</a>
					<div className="menu__links">
						{links.map(link => (
							<a
								key={link.href}
								className="menu__link menu_link"
								href={link.href}
								target="_blank"
								rel="noreferrer"
							>
								<svg className="menu_link__icon">
									<use xlinkHref={link.icon}></use>
								</svg>
								<span className="menu_link__text">
									{link.name}
								</span>
							</a>
						))}
					</div>
				</SimpleBar>
			</div>
		</div>
	);
};

export default MenuContent;
