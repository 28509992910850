import React, { useContext } from 'react';
import { ActivePointContext } from '../../context';

const AboutTab = () => {
	const { activePoint } = useContext(ActivePointContext);
	const categories =
		typeof activePoint.properties.categories === 'string'
			? JSON.parse(activePoint.properties.categories)
			: activePoint.properties.categories;
	return (
		<div className="point_card_tabs__tab point_card_tab point_card_tab--about">
			<h3 className="point_card__subheading point_card_subheading">
				В этом месте можно
			</h3>
			<ul className="point_card_tab__categories">
				{categories.map(category => (
					<li
						key={category.id}
						className="point_card_tab__category point_category"
					>
						<div
							className={`point_category__icon point_category__icon--type-${category.category_id}`}
						>
							<svg className="point_category__icon_content">
								<use xlinkHref={`#${category.icon}`}></use>
							</svg>
						</div>
						<p className="point_category__name">{category.name}</p>
					</li>
				))}
			</ul>
		</div>
	);
};

export default AboutTab;
