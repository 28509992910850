import { useState } from "react"

export const useFetching = cb => {
	const [isLoading, setisLoading] = useState(false);
	const [error, setError] = useState('');
	const fetching = async _ => {
		try {
			setisLoading(true);
			await cb();
		}
		catch (e) {
			setError(e.message)
		}
		finally {
			setisLoading(false)
		}

	}
	return [fetching, isLoading, error];
}