import React, { useContext } from 'react';
import { ActivePointContext } from '../../context';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';


const DescriptionTab = () => {
	const { activePoint } = useContext(ActivePointContext);

	const descripionHTML = DOMPurify.sanitize(activePoint.properties.description, {
		USE_PROFILES: { html: true },
	});

	return (
		<div className="point_card_tabs__tab point_card_tab point_card_tab--description">
			<h3 className="point_card__subheading point_card_subheading">Описание</h3>
			<div className="point_card_tab__description">
				{parse(descripionHTML)}
			</div>
		</div>
	);
};

export default DescriptionTab;
