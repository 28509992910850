import React, { useContext } from 'react';
import Form from '../Form/Form';
import Input from '../Input/Input';
import Textarea from '../Textarea/Textarea';
import { ReviewContext, FeedbackContext } from '../../context';
import './Review.css';
import { CSSTransition } from 'react-transition-group';

const Review = ({ serviceId }) => {
	const { setReviewIsVisible } = useContext(ReviewContext);
	const { setFeedbackIsVisible } = useContext(FeedbackContext);
	const isMobile = window.matchMedia('(max-width: 768px)').matches;
	return (
		<div className="review">
			<div
				className="review__overlay"
				onClick={_ => setReviewIsVisible(false)}
			></div>
			<div className="review__content">
				<Form
					classes={['review__form']}
					sendText="Отправить отзыв"
					endpoint="https://api-oc.itome.site/api/v1/review"
					successBtnCallback={_ => setReviewIsVisible(false)}
					successTitle={'Ваш отзыв\nотправлен'}
					successText={`Спасибо за предоставленную информацию!\n\В скором времени мы её обработаем`}
					successBtnText="Замечательно"
				>
					{isMobile && (
						<div className="modal_controls review__controls">
							<button
								class="modal_back_btn"
								onClick={_ => setReviewIsVisible(false)}
							>
								<svg class="modal_back_btn__icon">
									<use xlinkHref="#chevron"></use>
								</svg>
								<span className="modal_back_btn__text">
									Назад
								</span>
							</button>
							<button
								class="modal_close_btn"
								onClick={_ => setReviewIsVisible(false)}
							>
								<svg class="modal_close_btn__icon">
									<use xlinkHref="#cross"></use>
								</svg>
							</button>
						</div>
					)}
					<h2 className="form__title">Оставить отзыв</h2>
					<p className="form__text">
						Нам очень интересно и полезно узнать ваше мнение об этом
						месте. Мы будем очень рады, если вы оставите свои
						пожелания и отзывы.
					</p>
					<p className="form__text">
						Также, вы можете{' '}
						<button
							className="inline_btn"
							type="button"
							onClick={_ => setFeedbackIsVisible(true)}
						>
							Задать вопрос
						</button>
						<br /> и мы обязательно на него ответим.
					</p>
					<input type="hidden" name="service_id" value={serviceId} />
					<Input
						required
						name="name_from"
						type="text"
						label="Как вас зовут?"
						classes={['form__input']}
					/>
					<Input
						required
						name="email_from"
						type="email"
						label="Email"
						classes={['form__input']}
					/>
					<Textarea
						required
						name="text"
						label="Ваш отзыв"
						classes={['form__input input--textarea']}
						rows="8"
					/>
				</Form>
			</div>
		</div>
	);
};

export default Review;
