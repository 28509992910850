import React, { useEffect, useState } from 'react';
import { useFetching } from '../../hooks/useFetching';
import Preloader from '../Preloader';
import './Form.css';

const Form = ({
	classes = [],
	children,
	sendIcon = 'send',
	sendText = 'Отправить',
	endpoint = 'https://api-oc.itome.site/test',
	successTitle = 'Спасибо!',
	successText = `Ваше сообщение успешно отправлено. \nОжидайте от нас обратной связи.`,
	successBtnText = 'ОК',
	successBtnCallback
}) => {
	const className = ['form', ...classes].join(' ');
	const [isOnSuccess, setIsOnSuccess] = useState(false);
	const [data, setData] = useState(null);


	const [sendForm, isSending, formSengindError] = useFetching(async _ => {
		const req = await fetch(endpoint, {
			method: 'POST',
			body: data,
		});
		const res = await req.text();
		setIsOnSuccess(true);
	});

	const handleSubmit = evt => {
		evt.preventDefault();
		const formData = new FormData(evt.target);
		setData(formData)
		
	};

	useEffect(_ => {
		if (data) {
			sendForm()
		}
	}, [data]);


	const getContent = _ => {
		if (isSending)
			return (
				<>
					<div className="form__content on-request">{children}</div>
					<div className="form__loader">
						<Preloader />
					</div>
				</>
			);

		if (isOnSuccess)
			return (
				<>
					<div className="form__content">
						<div class="form__succes_icon form_success">
							<svg className="form_success__mark">
								<use xlinkHref="#success_sharp"></use>
							</svg>
						</div>
						<h2 className="form__title">{successTitle}</h2>
						<p className="form__text">{successText}</p>
						<button
							type="button"
							className="form__button big_btn big_btn--grey"
							onClick={_ => successBtnCallback && successBtnCallback()}
						>
							<span className="big_btn__text">
								{successBtnText}
							</span>
						</button>
					</div>
				</>
			);
		// if (formSengindError) return
		return (
			<>
				<div className="form__content">{children}</div>
				<button className="form__button big_btn">
					<svg className="big_btn__icon">
						<use xlinkHref={`#${sendIcon}`}></use>
					</svg>
					<span className="big_btn__text">{sendText}</span>
				</button>
				<p className="form__privacy_text">
					Нажимая на кнопку, вы соглашаетесь с
					<a
						href="https://opencity.life/privacy"
						target="_blank"
						rel="noreferrer"
					>
						политикой обработки персональных данных
					</a>
				</p>
			</>
		);
	};

	return (
		<form className={className} onSubmit={handleSubmit}>
			{getContent()}
		</form>
	);
};

export default Form;
