import React, {
	useLayoutEffect,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import AppliedFilter from './AppliedFilter';
import './AppliedFilters.css';
import { FiltersContext } from '../../context';

import HiddenFilters from './HiddenFilters';

const AppliedFilters = () => {
	const listEl = useRef(null);
	const { filters, setFilters } = useContext(FiltersContext);
	const [visibleFilters, setVisibleFilters] = useState([...filters]);
	const [hiddenFilters, setHiddenFilters] = useState([]);
	const btnsRefs = useRef([...filters].map(_ => null));
	const hiddenFiltersRef = useRef(null);
	const [hiddenListIsVisible, setHiddenListIsVisible] = useState(false);
	const isMobile = window.matchMedia('(max-width: 768px)').matches;


	useEffect(
		_ => {
			setVisibleFilters([...filters]);
			setHiddenFilters([]);
		},
		[filters]
	);
	

	useLayoutEffect(
		_ => {
			const btns = btnsRefs.current.filter(el => el);
			const widthToCompare = listEl.current.clientWidth - (isMobile ? 125 : 200);
			const btnsWidth = _ =>
				btns.reduce((width, btn) => (width += btn.offsetWidth + 8), 0);
			if (btnsWidth() > widthToCompare) {
				let lastIndex = btns.length;
				while (
					lastIndex &&
					btnsWidth() > widthToCompare
				) {
					lastIndex--;
					btns.pop();
				}
				setVisibleFilters(visibleFilters.slice(0, lastIndex));
				setHiddenFilters(visibleFilters.slice(lastIndex));
			}

			btns.forEach((btn, i) => {
				if (i) {
					const prev = btns[i - 1];
					const offset =
						prev.offsetWidth +
						8 +
						(prev.dataset.offset ? +prev.dataset.offset : 0);
					btn.style.transform = `translateX(${offset}px)`;
					btn.dataset.offset = offset;
				} else {
					btn.style.transform = `translateX(0)`;
					btn.dataset.offset = 0;
				}
			});
			if (hiddenFiltersRef.current && btns.length) {
				const offset =
					btns[btns.length - 1].offsetWidth +
					+btns[btns.length - 1].dataset.offset +
					8;

				hiddenFiltersRef.current.style.transform = `translateX(${offset}px)`;
				hiddenFiltersRef.current.style.transition = `none`;
			}
			
		},
		[visibleFilters]
	);

	useEffect(_ => {
		if (!hiddenFiltersRef.current) setHiddenListIsVisible(false);
	}, [hiddenFiltersRef.current])

	return (
		<div className="applied_filters" ref={listEl}>
			{visibleFilters.map((filter, i) => (
				<AppliedFilter
					filter={filter[1]}
					ref={ref => (btnsRefs.current[i] = ref)}
					key={filter[1].id}
					classes={['applied_filters__item']}
				/>
			))}
			{hiddenFilters.length > 0 && (
				<HiddenFilters ref={hiddenFiltersRef} visibleCount={visibleFilters.length} filters={hiddenFilters} isVisible={hiddenListIsVisible} setIsVisible={setHiddenListIsVisible}/>
			)}
		</div>
	);
};

export default AppliedFilters;
