import React, { useEffect, useMemo, useContext } from 'react';
import { MapContext } from '../../context';

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { createPortal } from 'react-dom';
import Cluster from './Cluster';
import Marker from './Marker';

const MapPoint = ({ data }) => {
	const { mapRef } = useContext(MapContext);

	const marker = useMemo(_ => {
		const el = document.createElement('div');

		const mrkr = new mapboxgl.Marker({
			element: el,
			offset: [0, -46],
		}).setLngLat(data.geometry.coordinates);

		return mrkr;
	}, []);

	useEffect(_ => {
		marker.addTo(mapRef.current);

		return _ => marker.remove();
	}, []);

	if (data.properties.cluster) {
		return createPortal(<Cluster data={data} />, marker.getElement());
	} else {
		return createPortal(<Marker data={data} />, marker.getElement());
	}
};

export default React.memo(MapPoint);
