import React, { useContext } from 'react';
import Form from '../Form/Form';
import Input from '../Input/Input';
import Textarea from '../Textarea/Textarea';
import './Feedback.css';
import { CSSTransition } from 'react-transition-group';
import { FeedbackContext } from '../../context';

const Feedback = () => {
	const { feedbackIsVisible, setFeedbackIsVisible } =
		useContext(FeedbackContext);

	return (
		<CSSTransition
			in={feedbackIsVisible}
			timeout={600}
			unmountOnExit
			classNames="feedback"
		>
			<div className="feedback">
				<div
					className="feedback__overlay"
					
				></div>
				<button className="feedback__back_btn back_btn" onClick={_ => setFeedbackIsVisible(false)}>
					<div className="back_btn__icon">
						<svg className="back_btn__icon_content">
							<use xlinkHref="#chevron"></use>
						</svg>
					</div>
					<span className="back_btn__text">Выйти</span>
				</button>
				<div className="feedback__content">
					<Form
						classes={['feedback__form']}
						sendText="Отправить сообщение"
						endpoint="https://api-oc.itome.site/api/v1/feedback"
						successBtnCallback={_ => setFeedbackIsVisible(false)}
					>
						<h2 className="form__title">Обратная связь</h2>
						<p className="form__text">
							Здесь вы можете задать любой интересующий вас
							вопрос. Мы свяжемся с вами в ответном письме.
						</p>
						<Input
							required
							name="name"
							type="text"
							label="Как вас зовут?"
							classes={['form__input']}
						/>
						<Input
							required
							name="email"
							type="email"
							label="Email"
							classes={['form__input']}
						/>
						<Textarea
							required
							name="text"
							label="Ваше сообщение"
							classes={['form__input input--textarea']}
							rows="8"
						/>
					</Form>
				</div>
			</div>
		</CSSTransition>
	);
};

export default Feedback;
