import 'mapbox-gl/dist/mapbox-gl.css';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import './App.css';
import AppControls from './components/AppControls/AppControls';
import AppliedFilters from './components/AppliedFilters/AppliedFilters';
import AppPreloader from './components/AppPreloader/AppPreloader';
import AppRouter from './components/AppRouter';
import CitiesListContainer from './components/CitiesList/CitiesListContainer';
import Feedback from './components/Feedback/Feedback';
import FiltersListContainer from './components/FiltersList/FiltersListContainer';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import AppMap from './components/Map/Map';
import PointCard from './components/PointCard/PointCard';
import Preloader from './components/Preloader';
import Search from './components/Search/Search';
import SearchListContainer from './components/Search/SearchListContainer';
import MenuContainer from './components/Menu/MenuContainer';
import ReviewContainer from './components/PointCard/ReviewContainer';

import {
	ActivePointContextProvider,
	CitiesContextProvider,
	FiltersContextProvider,
	MapContextProvider,
	PortalsRefsContextProvider,
	FooterContextProvider,
	FeedbackContextProvider,
	HeaderActiveModalContextProvider,
} from './context';


function App() {
	const [preloaderAnimationComplete, setPreloaderAnimationComplete] =
		useState(false);

	useEffect(_ => {
		setTimeout(_ => {
			setPreloaderAnimationComplete(true);
		}, 2000);
	}, []);

	const isMobile = window.matchMedia('(max-width: 768px)').matches;

	return (
		<MapContextProvider>
			<ActivePointContextProvider>
				<PortalsRefsContextProvider>
					<CitiesContextProvider>
						<FiltersContextProvider>
							<FooterContextProvider>
								<FeedbackContextProvider>
									<HeaderActiveModalContextProvider>
										<div className="App">
											<AppPreloader
												preloaderAnimationComplete={
													preloaderAnimationComplete
												}
											/>

											<div className="app__header_container">
												<Header />
												<AppliedFilters />
												{!isMobile && (
													<CitiesListContainer />
												)}
												{!isMobile && (
													<SearchListContainer />
												)}
											</div>

											{isMobile && <Search />}
											{isMobile && <SearchListContainer />}

											{isMobile && (
												<CitiesListContainer />
											)}
											{isMobile && (
												<FiltersListContainer />
											)}
											<AppControls />

											<AppRouter />
										</div>
										<MenuContainer />

										<ReviewContainer />

										<Feedback />

										<Footer />
									</HeaderActiveModalContextProvider>
								</FeedbackContextProvider>
							</FooterContextProvider>
						</FiltersContextProvider>
					</CitiesContextProvider>
				</PortalsRefsContextProvider>
			</ActivePointContextProvider>
		</MapContextProvider>
	);
}
export default App;
