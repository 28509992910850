import React, { useContext, useState, useRef } from 'react';
import { MapContext, ActivePointContext } from '../../context';
import RippleEffect from '../RippleEffect';

const Cluster = ({ data }) => {
	const { activePoint, setActivePoint } = useContext(ActivePointContext);
	const classNames = ['point_button'];
	if (activePoint && +activePoint.id === +data.properties.id) {
		classNames.push('is-active');
	}
	const { mapRef } = useContext(MapContext);
	const source = mapRef.current.getSource('points');

	const [isClicked, setIsClicked] = useState(false);
	const [clickCoords, setClickCoords] = useState({ x: -1, y: -1 });
	const btnRef = useRef(null);

	const handleClick = evt => {
		setActivePoint(null);
		setClickCoords({ x: evt.clientX, y: evt.clientY });
		setIsClicked(true);
		source.getClusterExpansionZoom(
			data.properties.cluster_id,
			(error, zoom) => {
				mapRef.current.flyTo({
					center: data.geometry.coordinates,
					zoom: zoom + 1,
					duration: 700
				});
			}
		);
	};

	return (
		<button
			className={classNames.join(' ')}
			onClick={handleClick}
		>
			<img
				className="point_button__bg"
				src="/assets/img/cluster.svg"
				alt=""
			/>
			<div className="point_button__counter">
				{data.properties.point_count}
			</div>
			<div className="ripple_container" ref={btnRef}>
				{isClicked && (
					<RippleEffect
						clickTarget={btnRef.current}
						clickCoords={clickCoords}
					/>
				)}
			</div>
		</button>
	);
};

export default Cluster;
