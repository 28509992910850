import React, { useContext } from 'react';
import { FiltersContext } from '../../context';

const AppliedFilter = React.forwardRef( ({ filter, classes = [] }, ref) => {
	const { filters, setFilters } = useContext(FiltersContext);
	const classNames = ['applied_filter', ...classes].join(' ');
	const handleClick = id => {
		const filtersCopy = new Map(filters);
		filtersCopy.delete(id);
		setFilters(filtersCopy);

	}
	return (
		<button ref={ref} className={classNames} onClick={handleClick.bind(this, filter.id)} title={filter.name}>
			<span className="applied_filter__text">{filter.name}</span>
			<div className="applied_filter__remove_icon">
				<svg className="applied_filter__remove_icon_content">
					<use xlinkHref="#cross"></use>
				</svg>
			</div>
		</button>
	);
});

export default AppliedFilter;
