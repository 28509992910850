import React, { useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import utils from '../../helpers/utils';
import AppliedFilter from './AppliedFilter';

const HiddenFilters = React.forwardRef(({ filters, isVisible, setIsVisible, visibleCount }, ref) => {
	
	const handleClick = _ => {
		setIsVisible(!isVisible);
	};
	return (
		<div
			className="applied_filters__hidden_filters hidden_filters applied_filters__item"
			ref={ref}
		>
			<button className={`applied_filter hidden_filters__toggle ${isVisible ? 'is-clicked' : '' }`} onClick={handleClick}>
				<span className="applied_filter__text">
					{visibleCount > 0 && 'Ещё'} {filters.length}{' '}
					{utils.pluralize(filters.length, [
						'фильтр',
						'фильтра',
						'фильтров',
					])}
				</span>
				<svg className="applied_filter__arrow">
					<use xlinkHref="#chevron"></use>
				</svg>
			</button>
			<CSSTransition
				in={isVisible}
				unmountOnExit
				classNames="hidden_filters__container"
				timeout={170}
			>
				<div className="hidden_filters__list">
					{filters.map((filter, i) => (
						<AppliedFilter
							filter={filter[1]}
							key={filter[1].id}
							classes={['hidden_filters__item']}
						/>
					))}
				</div>
			</CSSTransition>
		</div>
	);
});

export default HiddenFilters;
