import React, { useContext, useState, useRef } from 'react';
import { MapContext, ActivePointContext } from '../../context';
import MarkerTooltip from './MarkerTooltip';

const Marker = ({ data }) => {
	const { activePoint, setActivePoint, activePointIsVisible } =
		useContext(ActivePointContext);
	const classNames = ['point_button'];
	if (
		activePointIsVisible &&
		activePoint &&
		+activePoint.properties.id === +data.properties.id
	) {
		classNames.push('is-active');
	}

	const [isClicked, setIsClicked] = useState(false);
	
	const { mapRef, setPrevCameraOptions } = useContext(MapContext);
	

	const props = data.properties;

	const categories =
		typeof props.categories === 'string'
			? JSON.parse(props.categories)
			: props.categories;

	const iconHref = categories.length > 1 ? 'dots' : categories[0].icon;

	const handleClick = evt => {
		
		setIsClicked(true);
		setPrevCameraOptions({
			center: mapRef.current.getCenter()
		});


		mapRef.current.flyTo({
			center: data.geometry.coordinates,
			duration: 500
		});

		setActivePoint(data);
	};

	return (
		<button className={classNames.join(' ')} onClick={handleClick}>
			<img
				className="point_button__bg"
				src="/assets/img/marker.svg"
				alt=""
			/>
			<div className="point_button__icon">
				<svg>
					<use xlinkHref={`#${iconHref}`}></use>
				</svg>
			</div>
			<MarkerTooltip
				name={data.properties.name}
				address={data.properties.address}
				shortDescription={data.properties.short_description}
				iconHref={iconHref}
			/>

		</button>
	);
};

export default Marker;
