import React, { useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import { PortalsRefsContext, HeaderActiveModalContext } from '../../context';
import HeaderButton from '../Header/HeaderButton';
import MenuContent from './MenuContent';
import './Menu.css';

const Menu = () => {
	const { menuRootRef } = useContext(PortalsRefsContext);

	const root = menuRootRef.current;

	const { activeModal, setActiveModal } = useContext(
		HeaderActiveModalContext
	);
	const [isVisible, setIsVisible] = useState(false);

	useEffect(
		_ => {
			setIsVisible(activeModal === 'menu');
		},
		[activeModal]
	);

	useEffect(
		_ => {
			isVisible && setActiveModal('menu');
		},
		[isVisible]
	);

	const handleOnCLick = _ => {
		setIsVisible(!isVisible);
	};

	if (!root) return '';

	return (
		<>
			<HeaderButton
				onClick={handleOnCLick}
				className={isVisible ? 'is-clicked' : ''}
			>
				<svg className="header_btn__icon">
					<use xlinkHref="#menu"></use>
				</svg>
				<span className="header_btn__text">Меню</span>
			</HeaderButton>
			<CSSTransition
				in={isVisible}
				unmountOnExit
				classNames="menu"
				timeout={170}
			>
				<>{createPortal(<MenuContent />, root)}</>
			</CSSTransition>
		</>
	);
};

export default Menu;
