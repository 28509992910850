import React, { useContext } from 'react';
import { MapContext, SearchContext } from '../../context';

const SearchResultItem = ({ item, group, classes = [] }) => {
	const className = ['search_result', ...classes].join(' ');
	const { name, address, id } = item.properties;
	const { mapRef } = useContext(MapContext);
	const { setNeedToCheckLocalStorage, setIsClicked } = useContext(SearchContext);

	const handleClick = _ => {
		mapRef.current.flyTo({
			center: item.geometry.coordinates,
			zoom: 18,
			duration: 50,
		});

		const prevItems = localStorage.prevSearchResults
			? JSON.parse(localStorage.prevSearchResults)
			: [];

		const itemToPush = group ? group : [{ item }];
		const propNameToCompare = group ? 'original_id' : 'id';
		const propToCompare = group ? group[0].item.properties.original_id : id;

		!prevItems.find(
			arr => +arr[0].item.properties[propNameToCompare] === +propToCompare
		) && prevItems.push(itemToPush);

		localStorage.prevSearchResults = JSON.stringify(prevItems);
		setNeedToCheckLocalStorage(true);
		setIsClicked(false);
		
		
	};
	return (
		<button className={className} onClick={handleClick}>
			<svg className="search_result__icon">
				<use xlinkHref="#result_item_mark"></use>
			</svg>
			<div className="search_result__text">
				<p className="search_result__title">{address}</p>
				<p className="search_result__caption">{name}</p>
			</div>
		</button>
	);
};

export default SearchResultItem;
