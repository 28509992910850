import React, { useContext } from 'react';
import { PortalsRefsContext } from '../../context';

const CitiesListContainer = () => {
	const { citiesRootRef } = useContext(PortalsRefsContext);
	const isMobile = window.matchMedia('(max-width: 768px)').matches;

	if (isMobile)
		return (
			<div id="citiesListRoot" className="cities_list">
				<div className="cities_list__wrapper" ref={citiesRootRef}></div>
			</div>
		);
		
	return (
		<div
			ref={citiesRootRef}
			id="citiesListRoot"
			className="cities_list"
		></div>
	);
};

export default CitiesListContainer;
