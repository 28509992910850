import React, { useState, useContext, useEffect } from 'react';
import HeaderButton from '../Header/HeaderButton';
import {
	MapContext,
	HeaderActiveModalContext,
	FiltersContext,
} from '../../context';
import { CSSTransition } from 'react-transition-group';
import Preloader from '../Preloader';
import { createGeoJSONCircle } from '../../helpers/geojson';

const NearbyLocator = () => {
	const [isVisible, setIsVisible] = useState(false);
	const { activeModal, setActiveModal } = useContext(
		HeaderActiveModalContext
	);
	const [error, setError] = useState(null);

	const [isLoading, setIsLoading] = useState(false);

	const { filters, setFilters } = useContext(FiltersContext);

	const { userPosition, setUserPosition, setUserPositionRadius } =
		useContext(MapContext);

	const btnText = window.matchMedia(
		'(max-width: 1000px) and (min-width: 769px)'
	).matches
		? 'Рядом'
		: 'Найти рядом';

	useEffect(
		_ => {
			if (!filters.has('geolocation')) {
				setIsVisible(false);
			}
		},
		[filters]
	);

	useEffect(
		_ => {
			isVisible && setActiveModal('geolocation');
			if (!isVisible) {
				const filtersCopy = new Map(filters);
				filtersCopy.delete('geolocation');
				setFilters(filtersCopy);
			} else {
				requestGeoLocation();
			}
		},
		[isVisible]
	);

	useEffect(
		_ => {
			userPosition &&
				setUserPositionRadius(
					createGeoJSONCircle(
						{
							longitude: userPosition[0],
							latitude: userPosition[1],
						},
						1
					)
				);
		},
		[userPosition, filters]
	);

	const handleOnCLick = _ => {
		if (isLoading) return;
		setIsVisible(!isVisible);
		setActiveModal('geolocation');
	};

	const requestGeoLocation = _ => {
		if (!navigator.geolocation) {
			setError(
				'Ваш браузер не поддерживает определение вашего местоположения'
			);
			return;
		}
		setIsLoading(true);
		// setWatchId(
		navigator.geolocation.getCurrentPosition(
			handleGeolocationRequestSuccess,
			handleGeolocationRequestError,
			{
				enableHighAccuracy: true,
				timeout: 10000,
			}
		);
		// );
	};

	const handleGeolocationRequestSuccess = pos => {
		setIsLoading(false);
		const filtersCopy = new Map(filters);
		filtersCopy.set('geolocation', {
			id: 'geolocation',
			name: 'Рядом со мной',
		});
		setUserPosition([pos.coords.longitude, pos.coords.latitude]);
		setUserPositionRadius(createGeoJSONCircle(pos.coords, 1));
		setFilters(filtersCopy);
	};

	const handleGeolocationRequestError = err => {
		setIsLoading(false);
		const { code } = err;
		switch (code) {
			case window.GeolocationPositionError.TIMEOUT:
				setError(
					'Время выполнения запроса геолокации вышло. Попробуйте ещё раз'
				);
				return;
			case window.GeolocationPositionError.PERMISSION_DENIED:
				setError(
					'Вы запретили браузеру использовать информацию о вашей геолокации. Измените этот параметр в настройках браузера и попробуйте ещё раз'
				);
				return;
			case window.GeolocationPositionError.POSITION_UNAVAILABLE:
				setError(
					'Невозможно определить ваше местоположение. Попробуйте обновить страницу'
				);
				return;
			default:
				setError('Ошибка геолокации. Попробуйте обновить страницу');
				break;
		}
	};

	return (
		<div className="header__modal_wrapper">
			<HeaderButton
				onClick={handleOnCLick}
				className={isVisible ? 'is-clicked' : ''}
			>
				{isLoading && (
					<div className="header_btn__preloader">
						<Preloader />
					</div>
				)}
				{!isLoading && (
					<svg className="header_btn__icon">
						<use xlinkHref="#locate"></use>
					</svg>
				)}

				<span className="header_btn__text">{btnText}</span>
			</HeaderButton>

			<CSSTransition
				in={isVisible && !!error}
				unmountOnExit
				classNames="geolocation_error"
				timeout={170}
			>
				<button
					className="geolocation_error"
					onClick={_ => {
						setError(null);
						setIsVisible(false);
					}}
				>
					{error}
					<div className="geolocation_error__close">
						<svg>
							<use xlinkHref="#cross"></use>
						</svg>
					</div>
				</button>
			</CSSTransition>
		</div>
	);
};

export default NearbyLocator;
