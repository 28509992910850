import React, { useContext } from 'react';
import { MapContext } from '../../context';
import Preloader from '../Preloader';
import './AppPreloader.css';


const AppPreloader = ({ preloaderAnimationComplete }) => {
	const classNames = ['preloader'];
	const { mapIsLoaded } = useContext(MapContext);
	const isHidden = mapIsLoaded && preloaderAnimationComplete;
	if (isHidden) classNames.push('is-hidden');

	return (
		<>
			<div className={classNames.join(' ')}>
				<div className="preloader__icon">
					<svg
						width="145"
						height="116"
						viewBox="0 0 145 116"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g id="p_back_1">
							<path
								d="M118.017 3.27562e-06L82.4998 25.531V90.3619L118.017 64.8309V3.27562e-06Z"
								fill="#FE8597"
							></path>
							<path
								d="M118.017 3.27562e-06L82.4998 25.531V90.3619L118.017 64.8309V3.27562e-06Z"
								fill="black"
								fillOpacity="0.06"
							></path>
						</g>
						<path
							id="p_back_2"
							d="M118.017 0.0474321L82.4998 25.5143L129.483 46.7178L165 21.2509L118.017 0.0474321Z"
							fill="#FE8597"
						></path>
						<path
							d="M35.2144 69.1341L0.0318604 94.3612L47.2205 115.657L82.4031 90.4303L35.2144 69.1341Z"
							fill="#4F82EC"
						></path>
						<path
							d="M35.2144 69.1341L0.0318604 94.3612L47.2205 115.657L82.4031 90.4303L35.2144 69.1341Z"
							fill="black"
							fillOpacity="0.12"
						></path>
						<path
							d="M35.1825 4.73772L0 29.9648L0 94.42L35.1825 69.1929L35.1825 4.73772Z"
							fill="#4F82EC"
						></path>
						<path
							d="M35.1825 4.73772L0 29.9648L0 94.42L35.1825 69.1929L35.1825 4.73772Z"
							fill="black"
							fillOpacity="0.06"
						></path>
						<path
							d="M35.5166 4.30108L0.405396 29.6794L47.3887 50.883L82.4999 25.5046L35.5166 4.30108Z"
							fill="#4F82EC"
						></path>
						<path
							d="M82.5017 25.4973L47.2175 50.8612V115.727L82.5017 90.3633V25.4973Z"
							fill="#4F82EC"
						></path>
					</svg>
				</div>
			</div>
			{preloaderAnimationComplete && !mapIsLoaded && (
				<div className="app__second_preloader">
					<Preloader />
				</div>
			)}
		</>
	);
};

export default AppPreloader;
