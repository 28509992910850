import React, { useState, useRef, useContext } from 'react';
import { FiltersContext, MapContext } from '../../context';
import RippleEffect from '../RippleEffect';

const categories = [
	{
		id: 2,
		title: 'Нужна помощь',
		classModifier: 'need_help',
	},
	{
		id: 1,
		title: 'Хочу помочь',
		classModifier: 'wanna_help',
	},
	{
		id: 3,
		title: 'Полезные сервисы',
		classModifier: 'services',
	},
];

const FiltersListItems = ({
	activeCategory,
	activeCategoryId,
	setActiveCategory,
	selectedFilters,
	setSelectedFilters,
}) => {
	const currentCategory = categories.find(c => +c.id === +activeCategoryId);

	const refs = useRef(activeCategory.map(_ => React.createRef()));
	const [clickCoords, setClickCoords] = useState({ x: -1, y: -1 });

	const { filters, setFilters } = useContext(FiltersContext);
	const { mapRef } = useContext(MapContext);

	const isMobile = window.matchMedia('(max-width: 768px)').matches;

	const handleClick = (id, evt) => {
		const filters = new Set(selectedFilters);
		const action = filters.has(id) ? 'delete' : 'add';
		filters[action](id);
		setSelectedFilters(filters);
		setClickCoords({ x: evt.clientX, y: evt.clientY });
	};

	return (
		<div className="filters_list__filters_wrapper">
			{!isMobile && (
				<div className="modal_controls cities_list__controls">
					<button
						class="modal_back_btn"
						onClick={_ => setActiveCategory(null)}
					>
						<svg class="modal_back_btn__icon">
							<use xlinkHref="#chevron"></use>
						</svg>
						<span className="modal_back_btn__text">Назад</span>
					</button>
				</div>
			)}

			<h3 className="filters_list__category_title">
				{currentCategory.title}
			</h3>

			{activeCategory.map((filter, i) => (
				<button
					className={`filters_list__item filter filter--${
						currentCategory.classModifier
					} ${selectedFilters.has(filter.id) ? 'is-selected' : ''}`}
					key={filter.id}
					onClick={handleClick.bind(this, filter.id)}
				>
					<div className="filter__icon">
						<svg className="filter__icon_content">
							<use xlinkHref={`#${filter.icon}`}></use>
						</svg>
						<svg className="filter__icon_success">
							<use xlinkHref="#success"></use>
						</svg>
					</div>
					<span className="filter__text">{filter.name}</span>
					<div className="ripple_container" ref={refs.current[i]}>
						{/* {selectedFilters.has(filter.id) && (
							<RippleEffect
								clickTarget={refs.current[i].current}
								clickCoords={clickCoords}
							/>
						)} */}
					</div>
				</button>
			))}
		</div>
	);
};

export default FiltersListItems;
