import React, { useContext } from 'react';
import { ActivePointContext } from '../../context';
import YmapLink from './YmapLink';

const getFormattedHref = ({ link_type, link_href }) => {
	switch (link_type) {
		case 'mail':
			return `mailto:${link_href}`;
		default:
			return link_href;
	}
};
const getFormattedLinkText = ({ link_type, link_href }) => {
	switch (link_type) {
		case 'site':
			return link_href
				.replace(/http:\/\/|https:\/\//, '')
				.replace(/\/$/, '');
		case 'vk':
			return 'ВКонтакте';
		case 'fb':
			return 'FaceBook';
		case 'ig':
			return 'Instagram';
		case 'youtube':
			return 'YouTube';
		case 'ok':
			return 'Одноклассники';
		default:
			return link_href;
	}
};

const ContactsTab = () => {
	const { activePoint } = useContext(ActivePointContext);
	const { address, working_hours } = activePoint.properties;

	const links =
		typeof activePoint.properties.links === 'string'
			? JSON.parse(activePoint.properties.links)
			: activePoint.properties.links;

	const phones =
		typeof activePoint.properties.phones == 'string'
			? JSON.parse(activePoint.properties.phones)
			: activePoint.properties.phones;
	const { coordinates } = activePoint.geometry;

	const contactLinkTypes = ['site', 'mail', 'wa', 'tg'];
	const banned = ['fb', 'ig'];
	const clearLinks = links.filter(link => !banned.includes(link.link_type));

	const linksCaptions = {
		mail: 'Email для любых запросов',
		site: 'Официальный сайт',
		wa: 'Ответим и здесь',
		tg: 'Напишите нам',
	};

	const contactLinks = phones
		.map(phone => ({
			href: `tel:${phone.phone}`,
			text: phone.phone,
			caption: 'Телефон для связи',
		}))
		.concat(
			clearLinks
				.filter(link => contactLinkTypes.includes(link.link_type))
				.map(link => ({
					href: getFormattedHref(link),
					text: getFormattedLinkText(link),
					caption: linksCaptions[link.link_type],
				}))
		);
	const socialLinks = clearLinks
		.filter(link => !contactLinkTypes.includes(link.link_type))
		.map(link => ({
			href: getFormattedHref(link),
			text: getFormattedLinkText(link),
			caption: linksCaptions[link.link_type],
		}));

	    const isMobile = window.matchMedia('(max-width: 768px)').matches;

	return (
		<div className="point_card_tabs__tab point_card_tab point_card_tab--contacts">
			<h3 className="point_card__subheading point_card_subheading">
				<div className="point_card_subheading__icon point_card_subheading__icon--address">
					<svg className="point_card_subheading__content">
						<use xlinkHref="#marker"></use>
					</svg>
				</div>
				Где найти?
			</h3>
			<p className="point_card__text">
				{address}
				<br />
				{working_hours && <span>Режим работы: </span>}
				{working_hours &&
					 working_hours.split('<br>').map((part, i) => (
						part && <span key={i}>
							{part}
							<br />
						</span>
					))}
				<YmapLink coordinates={coordinates} />
			</p>
			{contactLinks.length !== 0 && (
				<>
					<br />
					<h3 className="point_card__subheading point_card_subheading">
						<div className="point_card_subheading__icon point_card_subheading__icon--contacts">
							<svg className="point_card_subheading__content">
								<use xlinkHref="#phone"></use>
							</svg>
						</div>
						{`Связаться ${!isMobile ? 'с организацией' : ''}`}
					</h3>
					<ul className="point_card__links">
						{contactLinks.map((link, i) => (
							<li className="point_card__link_item" key={i}>
								<a
									target="_blank"
									rel="noreferrer"
									href={link.href}
									className="point_card_link"
								>
									<div className="point_card_link__text_wrapper">
										<p className="point_card_link__text">
											{link.text}
										</p>
										<p className="point_card_link__caption">
											{link.caption}
										</p>
									</div>
									<svg className="point_card_link__icon">
										<use xlinkHref="#chevron"></use>
									</svg>
								</a>
							</li>
						))}
					</ul>
				</>
			)}
			{socialLinks.length !== 0 && (
				<>
					<br />
					<h3 className="point_card__subheading point_card_subheading">
						<div className="point_card_subheading__icon point_card_subheading__icon--social">
							<svg className="point_card_subheading__content">
								<use xlinkHref="#social"></use>
							</svg>
						</div>
						Социальные сети
					</h3>
					<ul className="point_card__links">
						{socialLinks.map((link, i) => (
							<li className="point_card__link_item" key={i}>
								<a
									target="_blank"
									rel="noreferrer"
									href={link.href}
									className="point_card_link"
								>
									<div className="point_card_link__text_wrapper">
										<p className="point_card_link__text">
											{link.text}
										</p>
										<p className="point_card_link__caption">
											{link.caption}
										</p>
									</div>
									<svg className="point_card_link__icon">
										<use xlinkHref="#chevron"></use>
									</svg>
								</a>
							</li>
						))}
					</ul>
				</>
			)}
		</div>
	);
};

export default ContactsTab;
