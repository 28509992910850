import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import {
	CitiesContext,
	PortalsRefsContext,
	HeaderActiveModalContext,
} from '../../context';
import HeaderButton from '../Header/HeaderButton';
import './CitiesList.css';
import { CSSTransition } from 'react-transition-group';
import CitiesListContent from './CitiesListContent';

const CitiesList = () => {
	const { citiesRootRef } = useContext(PortalsRefsContext);

	const root = citiesRootRef.current;

	const { cities, currentCity } = useContext(CitiesContext);

	const { activeModal, setActiveModal } = useContext(
		HeaderActiveModalContext
	);
	const [isVisible, setIsVisible] = useState(false);

	const [isClicked, setIsClicked] = useState(false);

	const isMobile = window.matchMedia('(max-width: 768px)').matches;

	useLayoutEffect(
		_ => {
			isMobile &&
				root &&
				root.parentElement &&
				root.parentElement.classList.toggle('is-visible', isClicked);
		},
		[isClicked]
	);

	useEffect(
		_ => {
			setIsVisible(activeModal === 'citiesList');
		},
		[activeModal]
	);

	useEffect(
		_ => {
			isVisible && setActiveModal('citiesList');
			!isVisible && setIsClicked(false);
		},
		[isVisible]
	);

	const handleOnCLick = _ => {
		setIsVisible(!isVisible);
		isMobile && setIsClicked(true);
	};

	if (!root) return '';

	return (
		!!cities && (
			<>
				<HeaderButton
					onClick={handleOnCLick}
					className={isVisible ? 'is-clicked' : ''}
				>
					<svg className="header_btn__icon">
						<use xlinkHref="#compass"></use>
					</svg>
					<span className="header_btn__text">
						{currentCity || 'Выбрать город'}
					</span>
					<svg className="header_btn__state_icon">
						<use xlinkHref="#chevron"></use>
					</svg>
				</HeaderButton>
				<CSSTransition
					in={isVisible}
					unmountOnExit
					classNames="cities_list__container"
					timeout={250}
				>
					<>
						{createPortal(
							<CitiesListContent cities={cities} />,
							root
						)}
					</>
				</CSSTransition>
			</>
		)
	);
};

export default CitiesList;
