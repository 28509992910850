import React, {
	useEffect,
	useState,
	useRef,
	useLayoutEffect,
	useContext,
} from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import AboutTab from './AboutTab';
import DescriptionTab from './DescriptionTab';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import ContactsTab from './ContactsTab';
import { useSearchParams } from 'react-router-dom';
import { ActivePointContext, ReviewContext } from '../../context';

const tabBtns = [
	{ key: 1, title: 'О точке', slug: 'about' },
	{ key: 2, title: 'Описание', slug: 'description' },
	{ key: 3, title: 'Контакты', slug: 'contacts' },
];

const PointCardTabs = ({ cardHeight }) => {
	const [activeTab, setActiveTab] = useState(1);
	const highlightClassNames = [
		'point_card_tabs__btn_highlight',
		`point_card_tabs__btn_highlight--on-active-${activeTab}`,
	].join(' ');

	const btnsElement = useRef(null);
	const contentElement = useRef(null);

	const [maxHeight, setMaxHeight] = useState(0);

	const { setReviewIsVisible } = useContext(ReviewContext);

	useLayoutEffect(
		_ => {
			if (contentElement.current) {
				setMaxHeight(cardHeight - contentElement.current.offsetTop);
			}
		},
		[cardHeight]
	);

	const { activePointIsVisible } = useContext(ActivePointContext);

	const [searchParams, setSearchParams] = useSearchParams();

	const currentTab = searchParams.get('tab') || 'about';

	useEffect(
		_ => {
			const tab = tabBtns.find(btn => btn.slug === currentTab);
			const { key } = tab;
			setActiveTab(key);
		},
		[currentTab]
	);

	const getTab = _ => {
		switch (activeTab) {
			case 1:
				return <AboutTab />;
			case 2:
				return <DescriptionTab />;
			case 3:
				return <ContactsTab />;
			default:
				return '';
		}
	};

	const prevActiveTab = useRef(0);

	useEffect(
		_ => {
			prevActiveTab.current = activeTab;
		},
		[activeTab]
	);

	return (
		<div className="point_card__tabs point_card_tabs">
			<div className="point_card_tabs__btns" ref={btnsElement}>
				<div className={highlightClassNames}></div>
				{tabBtns.map(tab => (
					<button
						className="point_card_tabs__btn"
						key={tab.key}
						onClick={_ => {
							setActiveTab(tab.key);
							const tabData = tabBtns.find(
								btn => +btn.key === +tab.key
							);
							const { slug } = tabData;
							setSearchParams({ tab: slug });
						}}
					>
						{tab.title}
					</button>
				))}
			</div>
			<div className="point_card_tabs__wrapper" ref={contentElement}>
				<SwitchTransition>
					<CSSTransition
						key={activeTab}
						timeout={120}
						classNames={
							'point_card_tabs__tab point_card_tabs__tab-'
						}
					>
						<SimpleBar
							style={{ maxHeight: maxHeight - 30 }}
							autoHide={false}
						>
							<div
								className="point_card_tabs__content"
								style={{ minHeight: maxHeight - 30 }}
							>
								{getTab()}
								<div className="point_card__review_block">
									<h3 className="point_card__subheading point_card_subheading">
										Оставить отзыв
									</h3>
									<p className="point_card__text">
										Если у вас есть что сказать об этой
										организации, то вы можете оставить свой
										отзыв. Отзывы помогают в улучшении
										качества сервиса.
									</p>
									<br />
									<button className="point_card__review_btn big_btn" onClick={_ => setReviewIsVisible(true)}>
										<img
											className="big_btn__icon"
											src="/assets/img/review.svg"
											alt=""
										/>
										<span className="big_btn__text">
											Написать отзыв
										</span>
									</button>
								</div>
							</div>
						</SimpleBar>
					</CSSTransition>
				</SwitchTransition>
			</div>
		</div>
	);
};

export default PointCardTabs;
