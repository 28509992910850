import React from 'react';

const MarkerTooltip = ({ iconHref, name, address, shortDescription }) => {
	return (
		<div className="point_button__tooltip tooltip">
			<div className="tooltip__head">
				<div className="tooltip__icon">
					<svg className="tooltip__icon_symbol">
						<use xlinkHref={`#${iconHref}`}></use>
					</svg>
				</div>
				<div className="tooltip__head_text">
					<p className="tooltip__name">{name}</p>
					<p className="tooltip__address">{address}</p>
				</div>
			</div>
			{shortDescription && (
				<div className="tooltip__content">{shortDescription}</div>
			)}
		</div>
	);
};

export default MarkerTooltip;
