import React, { useContext, useRef, useLayoutEffect, useState } from 'react';
import {
	ActivePointContext,
	PortalsRefsContext,
	ReviewContext,
} from '../../context';
import './PointCard.css';
import PointCardTabs from './PointCardTabs';
import { createPortal } from 'react-dom';
import Review from './Review';
import { CSSTransition } from 'react-transition-group';

const PointCard = () => {
	const { activePoint, setActivePointIsVisible } =
		useContext(ActivePointContext);

	const { reviewRootRef } = useContext(PortalsRefsContext);
	const root = reviewRootRef.current;

	const handleCloseClick = evt => {
		setActivePointIsVisible(false);
	};

	const cardElement = useRef(null);

	const [cardHeight, setCardHeight] = useState(0);

	const [reviewIsVisible, setReviewIsVisible] = useState(false);

	const isMobile = window.matchMedia('(max-width: 768px)').matches;

	useLayoutEffect(_ => {
		setCardHeight(cardElement.current.clientHeight);
	}, []);

	return (
		<>
			<div className="point_card">
				<div
					onClick={handleCloseClick}
					className="point_card__overlay"
				></div>
				<div className="point_card__content" ref={cardElement}>
					{!isMobile && (
						<button
							onClick={handleCloseClick}
							className="point_card__close"
						>
							<svg className="point_card__close_icon">
								<use xlinkHref="#cross"></use>
							</svg>
						</button>
					)}
					{isMobile && (
						<div className="modal_controls point_card__controls">
							<button
								class="modal_back_btn"
								onClick={handleCloseClick}
							>
								<svg class="modal_back_btn__icon">
									<use xlinkHref="#chevron"></use>
								</svg>
								<span className="modal_back_btn__text">
									Назад
								</span>
							</button>
							<button
								class="modal_close_btn"
								onClick={handleCloseClick}
							>
								<svg class="modal_close_btn__icon">
									<use xlinkHref="#cross"></use>
								</svg>
							</button>
						</div>
					)}
					<div className="point_card__head">
						<h2 className="point_card__title">
							{activePoint.properties.name}
						</h2>
						{activePoint.properties.short_description && (
							<p className="point_card__short_description">
								{activePoint.properties.short_description}
							</p>
						)}
					</div>
					<ReviewContext.Provider
						value={{ reviewIsVisible, setReviewIsVisible }}
					>
						<PointCardTabs cardHeight={cardHeight} />
					</ReviewContext.Provider>
				</div>
			</div>
			<ReviewContext.Provider
				value={{ reviewIsVisible, setReviewIsVisible }}
			>
				{root && (
					<CSSTransition
						in={reviewIsVisible}
						timeout={120}
						unmountOnExit
						classNames="review"
					>
						<>
							{createPortal(
								<Review
									serviceId={activePoint.properties.id}
								/>,
								root
							)}
						</>
					</CSSTransition>
				)}
			</ReviewContext.Provider>
		</>
	);
};

export default PointCard;
