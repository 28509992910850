import React, { useContext } from 'react';
import { PortalsRefsContext } from '../../context';

const ReviewContainer = () => {
	const { reviewRootRef } = useContext(PortalsRefsContext);
	return (
		<div
			ref={reviewRootRef}
			id="reviewRoot"
			className="review_wrapper"
		></div>
	);
};

export default ReviewContainer;
