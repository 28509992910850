import React from 'react';

const YmapLink = ({ coordinates }) => {
	return (
		<a
			className="point_card__ymap_link ymap_link"
			href={`https://yandex.ru/maps/?whatshere[point]=${coordinates}&whatshere[zoom]=17`}
			target="_blank"
			rel="noreferrer"
		>
			<span className="ymap_link__text">
				Показать на Яндекс.Карте
				<svg className="ymap_link__icon">
					<use xlinkHref="#chevron"></use>
				</svg>
			</span>
			<img
				alt="Яндекс.Карта"
				className="ymap_link__pic"
				src={`https://static-maps.yandex.ru/1.x/?ll=${coordinates}&l=map&z=17&size=496,179&pt=${coordinates},comma`}
			/>
		</a>
	);
};

export default YmapLink;
