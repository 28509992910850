import React from 'react';
import MarkerTooltip from './MarkerTooltip';
const UserMarker = () => {
	return (
		<button className='point_button point_button--user' >
			<img
				className="point_button__bg"
				src="/assets/img/user.svg"
				alt=""
			/>
			<MarkerTooltip
			name="Ваше местоположение"
			shortDescription="Если браузер неправильно определил ваше местоположение, вы можете перетащить маркер в нужное место"
			iconHref="locate"
		/>
			
		</button>
	);
};

export default UserMarker;
