import React, { useState, useRef } from 'react';
import RippleEffect from '../RippleEffect';

const HeaderButton = ({ onClick, className, children }) => {

	const handleClick = evt => {
		setClickCoords({ x: evt.clientX, y: evt.clientY });
		onClick && onClick();
	};

	const [clickCoords, setClickCoords] = useState({ x: -1, y: -1 });
	const btnRef = useRef(null);

	return (
		<button
			className={['header__btn', 'header_btn', className].join(' ')}
			onClick={handleClick}
		>
			{children}
			<div className="ripple_container" ref={btnRef}>
				{className === 'is-clicked' && (
					<RippleEffect
						clickTarget={btnRef.current}
						clickCoords={clickCoords}
					/>
				)}
			</div>
		</button>
	);
};

export default HeaderButton;
