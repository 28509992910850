import React, { useContext } from 'react';
import { PortalsRefsContext } from '../../context';

const FiltersListContainer = () => {
	const { filtersRootRef } = useContext(PortalsRefsContext);
	return (
		<div
			id="filtersListRoot"
			className="filters_list_wrapper"
			ref={filtersRootRef}
		></div>
	);
};

export default FiltersListContainer;
