import React from 'react';

const MenuIcons = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="0"
			height="0"
			style={{position:'absolute'}}
		>
			<symbol id="menu1" viewBox="0 0 14 18">
				<path d="M7 9c.917 0 1.667-.75 1.667-1.667 0-.916-.75-1.666-1.667-1.666s-1.667.75-1.667 1.666C5.333 8.25 6.083 9 7 9zM7 .667c3.5 0 6.667 2.683 6.667 6.833 0 2.65-2.042 5.767-6.117 9.358a.848.848 0 0 1-1.108 0C2.375 13.267.333 10.15.333 7.5.333 3.35 3.5.667 7 .667z"></path>
			</symbol>

			<symbol id="menu10" viewBox="0 0 14 19">
				<path d="M12 6.667h-.833V5C11.167 2.7 9.3.833 7 .833A4.168 4.168 0 0 0 2.833 5v1.667H2c-.917 0-1.667.75-1.667 1.666v8.334c0 .916.75 1.666 1.667 1.666h10c.917 0 1.667-.75 1.667-1.666V8.333c0-.916-.75-1.666-1.667-1.666zm-5 7.5c-.917 0-1.667-.75-1.667-1.667s.75-1.667 1.667-1.667 1.667.75 1.667 1.667-.75 1.667-1.667 1.667zm-2.5-7.5V5c0-1.383 1.117-2.5 2.5-2.5S9.5 3.617 9.5 5v1.667h-5z"></path>
			</symbol>

			<symbol id="menu11" viewBox="0 0 20 20">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M5.556 5.84v-.007a4.444 4.444 0 1 1 8.888 0v.006c1.115.02 1.769.109 2.244.499.112.092.215.195.308.308.504.614.504 1.528.504 3.354v.833c0 2.857 0 4.286-.632 5.34a4.446 4.446 0 0 1-1.528 1.528c-1.055.632-2.483.632-5.34.632-2.857 0-4.285 0-5.34-.632a4.445 4.445 0 0 1-1.528-1.527C2.5 15.119 2.5 13.69 2.5 10.834V10c0-1.826 0-2.74.504-3.354.093-.113.196-.216.308-.308.475-.39 1.129-.479 2.244-.499zm6.666-.007a2.222 2.222 0 1 0-4.444 0h4.444zM7.5 8.89a1.11 1.11 0 0 1 1.111 1.111v1.667a1.111 1.111 0 1 1-2.222 0V10A1.11 1.11 0 0 1 7.5 8.889zm5 0a1.11 1.11 0 0 1 1.111 1.111v1.667a1.111 1.111 0 1 1-2.222 0V10A1.11 1.11 0 0 1 12.5 8.889z"
				></path>
			</symbol>

			<symbol id="menu12" viewBox="0 0 20 20">
				<path d="M10 1.667A8.336 8.336 0 0 0 1.667 10c0 4.6 3.733 8.333 8.333 8.333S18.334 14.6 18.334 10 14.6 1.667 10 1.667zm0 2.5c1.384 0 2.5 1.116 2.5 2.5 0 1.383-1.116 2.5-2.5 2.5a2.497 2.497 0 0 1-2.5-2.5c0-1.384 1.117-2.5 2.5-2.5zM10 16a6 6 0 0 1-5-2.683c.025-1.659 3.334-2.567 5-2.567 1.659 0 4.975.908 5 2.567A6 6 0 0 1 10 16z"></path>
			</symbol>

			<symbol id="menu2" viewBox="0 0 16 17">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M.5 12.058V5.714h15v6.395c0 2.616-1.646 4.224-4.281 4.224H4.773C2.163 16.333.5 14.692.5 12.058zm4.133-2.05a.685.685 0 0 1-.708-.665c0-.384.296-.7.675-.718.37 0 .675.293.683.667.017.384-.28.7-.65.716zm3.383 0a.685.685 0 0 1-.708-.665c0-.384.297-.7.676-.718.37 0 .675.293.683.667.016.384-.28.7-.65.716zm3.36 3.067a.706.706 0 0 1-.684-.708.693.693 0 0 1 .675-.708h.008c.387 0 .7.317.7.708a.704.704 0 0 1-.7.708zm-4.068-.708c.017.383.33.684.708.666a.684.684 0 0 0 .65-.715.681.681 0 0 0-.682-.668.712.712 0 0 0-.676.717zm-3.391 0a.684.684 0 0 0 .708.666.684.684 0 0 0 .65-.715.681.681 0 0 0-.683-.668.712.712 0 0 0-.675.717zM10.7 9.334c0-.383.297-.691.675-.7.37 0 .667.3.684.667a.678.678 0 1 1-1.358.042v-.009zm1.013-6.484c0 .368-.28.642-.634.642a.622.622 0 0 1-.634-.642V.908h-4.89V2.85a.622.622 0 0 1-.634.642.622.622 0 0 1-.634-.642V.908c-1.918.16-3.26 1.276-3.655 3.034-.077.312-.118 1.283-.13 1.772h14.995c-.01-.49-.052-1.468-.128-1.772-.396-1.742-1.754-2.866-3.656-3.034V2.85z"
				></path>
			</symbol>

			<symbol id="menu3" viewBox="0 0 20 12">
				<path d="M13.333 5.167a2.49 2.49 0 0 0 2.492-2.5 2.497 2.497 0 0 0-4.992 0c0 1.383 1.117 2.5 2.5 2.5zm-6.666 0a2.49 2.49 0 0 0 2.491-2.5 2.497 2.497 0 0 0-4.992 0c0 1.383 1.117 2.5 2.5 2.5zm0 1.666c-1.942 0-5.834.975-5.834 2.917V11c0 .458.375.833.834.833h10A.836.836 0 0 0 12.5 11V9.75c0-1.942-3.892-2.917-5.833-2.917zm6.666 0c-.241 0-.516.017-.808.042.017.008.025.025.033.033.95.692 1.609 1.617 1.609 2.842V11c0 .292-.059.575-.15.833h4.316a.836.836 0 0 0 .834-.833V9.75c0-1.942-3.892-2.917-5.834-2.917z"></path>
			</symbol>

			<symbol id="menu4" viewBox="0 0 20 20">
				<path d="M10 .77C4.91.77.77 4.91.77 10c0 5.09 4.14 9.23 9.23 9.23 5.09 0 9.23-4.14 9.23-9.23 0-5.09-4.14-9.23-9.23-9.23zm3.315 11.219c-.417.508-1.172 1.32-2.916 2.503a.707.707 0 0 1-.799 0c-1.744-1.184-2.5-1.997-2.916-2.503-.887-1.082-1.312-2.192-1.3-3.395.014-1.378 1.12-2.5 2.465-2.5.905 0 1.553.472 1.957.906a.266.266 0 0 0 .387 0c.404-.434 1.051-.906 1.957-.906 1.345 0 2.45 1.122 2.464 2.5.013 1.203-.412 2.314-1.3 3.395z"></path>
			</symbol>

			<symbol id="menu5" viewBox="0 0 16 20">
				<path d="M9 5c0-.55-.44-1-1-1-.55 0-1 .44-1 1v2H5c-.55 0-1 .44-1 1 0 .55.44 1 1 1h2v2c0 .55.44 1 1 1 .55 0 1-.44 1-1V9h2c.55 0 1-.44 1-1 0-.55-.44-1-1-1H9V5zM8 0c4.2 0 8 3.22 8 8.2 0 3.18-2.45 6.92-7.34 11.23-.38.33-.95.33-1.33 0C2.45 15.12 0 11.38 0 8.2 0 3.22 3.8 0 8 0z"></path>
			</symbol>

			<symbol id="menu6" viewBox="0 0 18 18">
				<path d="M9 .667A8.336 8.336 0 0 0 .667 9c0 4.6 3.733 8.333 8.333 8.333S17.333 13.6 17.333 9 13.6.667 9 .667zm0 12.5a.836.836 0 0 1-.833-.834V9c0-.458.375-.833.833-.833.458 0 .833.375.833.833v3.333a.836.836 0 0 1-.833.834zM9.833 6.5H8.167V4.833h1.666V6.5z"></path>
			</symbol>

			<symbol id="menu7" viewBox="0 0 16 16">
				<path d="M13.833.5H2.167C1.25.5.5 1.25.5 2.167v11.666c0 .917.75 1.667 1.667 1.667h11.666c.917 0 1.667-.75 1.667-1.667V2.167C15.5 1.25 14.75.5 13.833.5zM8.008 13c-.583 0-1.05-.467-1.05-1.05 0-.592.467-1.042 1.05-1.042.592 0 1.042.45 1.042 1.042-.008.575-.45 1.05-1.042 1.05zm2.509-6.167c-.634.925-1.234 1.217-1.559 1.809-.083.15-.133.266-.158.525a.749.749 0 0 1-.75.65H8a.731.731 0 0 1-.733-.8c.025-.284.091-.575.25-.859C7.925 7.433 8.7 7 9.15 6.358c.475-.675.208-1.941-1.142-1.941-.591 0-.983.3-1.225.658-.208.3-.575.442-.916.3a.727.727 0 0 1-.334-1.092A2.921 2.921 0 0 1 7.992 3c1.233 0 2.075.558 2.508 1.267.367.6.583 1.725.017 2.566z"></path>
			</symbol>

			<symbol id="menu8" viewBox="0 0 20 20">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M17.168 12.65c-1.152-1.151-3.007-1.087-4.222.064-.384.384-.64.896-.767 1.343-2.43-.703-5.565-3.773-6.268-6.204.511-.192.959-.448 1.343-.831 1.215-1.152 1.215-3.07.128-4.222-1.152-1.087-3.007-1.087-4.222.128-2.558 2.559-.32 7.548 3.198 11.065 3.454 3.39 8.123 5.437 10.746 2.815 1.151-1.152 1.215-3.07.064-4.158z"
				></path>
			</symbol>

			<symbol id="menu9" viewBox="0 0 20 20">
				<path d="M10 .77C4.905.77.77 4.904.77 10c0 5.095 4.135 9.23 9.23 9.23 5.095 0 9.23-4.135 9.23-9.23 0-5.095-4.135-9.23-9.23-9.23zm3.332 5.852c.988 0 1.782.793 1.782 1.781 0 .988-.794 1.782-1.782 1.782a1.777 1.777 0 0 1-1.781-1.782 1.77 1.77 0 0 1 1.781-1.781zM7.794 5.163c1.2 0 2.178.979 2.178 2.179 0 1.2-.978 2.178-2.178 2.178s-2.179-.978-2.179-2.178c0-1.21.97-2.179 2.179-2.179zm0 8.428v3.461a7.39 7.39 0 0 1-4.745-4.578c.97-1.034 3.388-1.56 4.745-1.56.49 0 1.108.074 1.754.203-1.514.803-1.754 1.865-1.754 2.474zM10 17.385c-.25 0-.49-.01-.73-.037V13.59c0-1.311 2.715-1.966 4.062-1.966.988 0 2.696.36 3.545 1.061-1.08 2.742-3.748 4.699-6.877 4.699z"></path>
			</symbol>
		</svg>
	);
};

export default MenuIcons;
