import React, { useRef, useState, useLayoutEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import utils from '../../helpers/utils';
import SearchResultItem from './SearchResultItem';

const SearchResultGroup = ({ group }) => {
	const points = `${group.length} ${utils.pluralize(group.length, [
		'точка',
		'точки',
		'точек',
	])}`;
	const {properties} = group[0].item;
	const name = +properties.original_id === 0 ? 'Города' : properties.name; 
	const groupRef = useRef(null);
	const [isOpen, setIsOpen] = useState(false);
	const [maxHeight, setMaxHeight] = useState(0);

	useLayoutEffect(() => {
		if (!isOpen) {
			setMaxHeight(0);
			return;
		}
		groupRef.current && setMaxHeight(groupRef.current.scrollHeight);
	}, [groupRef.current, isOpen]);

	return (
		<div className={`search_result_group ${isOpen ? 'is-open' : ''}`}>
			<button
				className="search_result search_result--group"
				onClick={_ => setIsOpen(!isOpen)}
			>
				<svg className="search_result__icon search_result__icon--group">
					<use xlinkHref="#result_group_mark"></use>
				</svg>
				<div className="search_result__text">
					<p className="search_result__title">{points}</p>
					<p className="search_result__caption">{name}</p>
				</div>
				<svg className="search_result__arrow">
					<use xlinkHref="#chevron"></use>
				</svg>
			</button>
			<CSSTransition
				in={isOpen}
				unmountOnExit
				classNames="search_result_group__items"
				timeout={200}
			>
				<div
					ref={groupRef}
					className="search_result_group__items"
					style={{ maxHeight }}
				>
					{group.map((result, i) => (
						<SearchResultItem
							key={i}
							item={result.item}
							classes={['search_result--group_item']}
							group={group}
						/>
					))}
				</div>
			</CSSTransition>
		</div>
	);
};

export default SearchResultGroup;
