import React, { useContext } from 'react';
import { MapContext } from '../../context';

const categories = [
	{
		id: 2,
		title: 'Нужна помощь',
		classModifier: 'need_help',
		icon: 'need_help',
		description:
			'Поиск организаций, которые могут предоставить вам профессиональную или материальную помощь',
		hasChildren: true,
	},
	{
		id: 1,
		title: 'Хочу помочь',
		classModifier: 'wanna_help',
		icon: 'wanna_help',
		description:
			'Поиск организаций, с помощью которых вы можете помочь людям и животным',
		hasChildren: true,
	},
	{
		id: 3,
		title: 'Полезные сервисы',
		classModifier: 'services',
		icon: 'services',
		description:
			'Поиск общественно полезных организаций, с помощью которых вы можете позаботиться об экологии',
		hasChildren: true,
	},
	{
		id: -1,
		title: 'События',
		classModifier: 'events',
		icon: 'events',
		description:
			'Поиск развлекательных и образовательных событий, концертов, выставок в вашем населённом пункте',
		hasChildren: false,
	},
];

const FiltersListContent = ({
	setActiveCategory,
	counters,
	selectedFilters,
	setSelectedFilters,
}) => {
	const isMobile = window.matchMedia('(max-width: 768px)').matches;
	const { mapRef } = useContext(MapContext);

	const handleClick = category => {
		category.hasChildren && setActiveCategory(category.id);
		if (+category.id === -1) {
			const filters = new Set(selectedFilters);
			const action = filters.has(category.id) ? 'delete' : 'add';
			filters[action](category.id.toString());
			setSelectedFilters(filters);
		}
	};

	return (
			<div className="filters_list__categories">
				{/* {isMobile && (
					<div className="modal_controls cities_list__controls">
						<button
							class="modal_back_btn"
							onClick={_ =>
								mapRef.current.flyTo({
									duration: 2,
								})
							}
						>
							<svg class="modal_back_btn__icon">
								<use xlinkHref="#chevron"></use>
							</svg>
							<span className="modal_back_btn__text">Назад</span>
						</button>
						<button
							class="modal_close_btn"
							onClick={_ =>
								mapRef.current.flyTo({
									duration: 2,
								})
							}
						>
							<svg class="modal_close_btn__icon">
								<use xlinkHref="#cross"></use>
							</svg>
						</button>
					</div>
				)} */}
				{categories.map(category => (
					<button
						className="filters_list__category filters_category"
						key={category.id}
						onClick={handleClick.bind(this, category)}
					>
						<div className="filters_category__head">
							<div
								className={`filters_category__icon filters_category__icon--${category.classModifier}`}
							>
								<svg className="filters_category__icon_content">
									<use xlinkHref={`#${category.icon}`}></use>
								</svg>
								{counters.find(c => +c.id === +category.id)
									?.count > 0 && (
									<div className="filters_category__count">
										{
											counters.find(
												c => +c.id === +category.id
											).count
										}
									</div>
								)}
							</div>
							<h3 className="filter_category__name">
								{category.title}
							</h3>
							{category.hasChildren && (
								<svg className="filter_category__arrow">
									<use xlinkHref="#chevron"></use>
								</svg>
							)}
						</div>
						<p className="filter_category__description">
							{category.description}
						</p>
					</button>
				))}
			</div>
	);
};

export default FiltersListContent;
