import Fuse from 'fuse.js';
import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import {
	CitiesContext,
	HeaderActiveModalContext,
	MapContext,
	PortalsRefsContext,
	SearchContext,
} from '../../context';
import './Search.css';
import SearchResults from './SearchResults';
const fuseOptions = {
	tokenize: true,
	matchAllTokens: true,
	includeScore: true,
	includeMatches: true,
	threshold: 0.2,
	location: 0,
	distance: 1000,
	maxPatternLength: 32,
	minMatchCharLength: 1,
	keys: [
		'properties.name',
		'properties.organization',
		'properties.phones.phone',
		// 'properties.description',
		'properties.address',
		'properties.links.link_href',
		'properties.categories.name',
		'name',
	],
};
const Search = () => {
	const [query, setQuery] = useState('');
	const [results, setResults] = useState([]);
	const [prevResults, setPrevResults] = useState([]);
	const [isPrevResults, setIsPrevResults] = useState(false);
	const [isVisible, setIsVisible] = useState(false);

	const fuseInstance = useRef(null);
	const { points } = useContext(MapContext);

	const { cities } = useContext(CitiesContext);

	const { searchResultsRootRef } = useContext(PortalsRefsContext);

	const { activeModal, setActiveModal } = useContext(
		HeaderActiveModalContext
	);
	const root = searchResultsRootRef.current;

	const [needToCheckLocalStorage, setNeedToCheckLocalStorage] =
		useState(true);

	const isMobile = window.matchMedia('(max-width: 768px)').matches;

	const [isClicked, setIsClicked] = useState(false);


	useLayoutEffect(_ => {
		root && root.classList.toggle('is-visible', isClicked);
		isClicked && setTimeout(_ => {
			window.scrollTo(0, 0);
		}, 500);


	}, [isClicked])

	useEffect(
		_ => {
			const pointsData = points.features;
			const citiesData = cities.features;
			if (!pointsData.length || !citiesData.length) return;

			const data = [
				...pointsData,
				...citiesData.map((city, i) => {
					return {
						...city,
						geometry: {
							coordinates: city.properties.center,
							type: 'Point',
						},
						properties: {
							id: -i,
							original_id: 0,
							name: city.properties.city,
							address: `${city.properties.region}, г. ${city.properties.city}`,
						},
					};
				}),
			];

			fuseInstance.current = new Fuse(data, fuseOptions);
		},
		[points, cities]
	);

	useEffect(
		_ => {
			if (!fuseInstance.current) {
				setIsVisible(false);
				return;
			}
			const searchResults = fuseInstance.current.search(query);

			if (query && !searchResults.length) {
				setResults([]);
				return;
			}
			if (!query && prevResults.length) {
				setResults(prevResults);
				return;
			}
			if (!query && !prevResults.length) {
				setResults([]);
				!isMobile && setIsVisible(false);
				return;
			}
			const grouppedResultsMap = new Map();
			searchResults.forEach(result => {
				const groupId = result.item.properties.original_id;
				const groupItems = grouppedResultsMap.has(groupId)
					? [...grouppedResultsMap.get(groupId)]
					: [];
				grouppedResultsMap.set(groupId, [...groupItems, result]);
			});
			const grouppedResults = [...grouppedResultsMap].map(
				group => group[1]
			);
			setResults(grouppedResults);
		},
		[query]
	);

	useEffect(
		_ => {
			results.length && setIsVisible(true);
			setIsPrevResults(
				prevResults.length > 0 &&
					results.length > 0 &&
					prevResults.length === results.length &&
					results.every(
						(arr, i) =>
							arr[0].item.properties.id ===
							prevResults[i][0].item.properties.id
					)
			);
		},
		[results]
	);

	useEffect(
		_ => {
			setIsVisible(activeModal === 'searchResults');
		},
		[activeModal]
	);

	useEffect(
		_ => {
			isVisible && setActiveModal('searchResults');
			!isVisible && !query && setResults([]);
		},
		[isVisible]
	);

	useEffect(
		_ => {
			if (!needToCheckLocalStorage) return;
			if (localStorage.prevSearchResults) {
				setPrevResults(JSON.parse(localStorage.prevSearchResults));
			} else {
				setPrevResults([]);
				if (isPrevResults) {
					setResults([]);
					setIsPrevResults(false);
				}
				!isMobile && setIsVisible(false);
			}
			setNeedToCheckLocalStorage(false);
		},
		[needToCheckLocalStorage]
	);

	const handleInput = evt => {
		setQuery(evt.target.value);
	};

	const handleFocus = _ => {
		if (isMobile) return;
		if (query) {
			setIsVisible(true);
		} else {
			if (prevResults.length) {
				setResults(prevResults);
				setIsVisible(true);
			} else {
				setIsVisible(false);
			}
		}
	};

	const handleClick = _ => {
		if (!isMobile) return;
		setIsClicked(true);
		setIsVisible(true)
		if (prevResults.length) {
			setResults(prevResults);
		}
	}
	const clear = _ => {
		!isMobile && setIsVisible(false);
		setTimeout(_ => {
			setQuery('');
		});
	};

	if (!root) return '';


	return (
		<>
			<div className={`header__search ${isClicked ? 'is-clicked' : ''}`}>
				<div className="search">
					<svg className="search__icon">
						<use xlinkHref="#search"></use>
					</svg>
					<input
						type="text"
						className="search__input"
						placeholder="Поиск организации, улицы, города"
						value={query}
						onChange={handleInput}
						onFocus={handleFocus}
						onClick={handleClick}
					/>
					{query.length > 0 && (
						<button className="search__clear" onClick={clear}>
							<svg className="search__clear_icon">
								<use xlinkHref="#cross"></use>
							</svg>
						</button>
					)}
				</div>
			</div>
			<CSSTransition
				in={isVisible}
				unmountOnExit
				classNames="search_list__container"
				timeout={250}
			>
				<>
					{createPortal(
						<SearchContext.Provider
							value={{
								setNeedToCheckLocalStorage,
								needToCheckLocalStorage,
								isClicked,
								setIsClicked
							}}
						>
							<SearchResults
								isPrevResults={isPrevResults}
								results={results}
								query={query}
								isVisible={isVisible}
							/>
						</SearchContext.Provider>,
						root
					)}
				</>
			</CSSTransition>

		</>
	);
};

export default Search;
